import firebase from "gatsby-plugin-firebase";
import thumb from '../images/icons/thumb.svg';
import arm from '../images/icons/arm.svg';
import { t } from '../lang';

const addPhoneOrEmail = async (val, type) => {
  try {
    const data = await firebase.firestore().collection(type).add({ value: val, created_at: new Date() });
    return { success: true, data: data.docs };
  }
  catch (err) {
    return { success: false, error: err };
  }
};

const inputValidator = (value) => {
  console.log(value);
  const email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
  const phone = /^\+?\d{9,12}$/.test(value);

  return email ?
    { type: 'email', text: `${t("Спасибо!")} <img src=${thumb} alt="thumb-up"/> ${t("Вы один из редких кому не безразличен наши дороги.")} <img src=${arm} alt="arm"/>` }
    : phone ? { type: "phone", text: `${t("Спасибо!")} <img src=${thumb} alt="thumb-up"/> ${t("Вы один из редких кому не безразличен наши дороги.")} <img src=${arm} alt="arm"/>` }
      : { type: null, text: t("То что вы ввели не похож на email или на номер телефона") }
}

export { addPhoneOrEmail, inputValidator };