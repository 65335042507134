import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --text: #2E384C;
    --green: #198779;
  }

  body {
    overflow-x: hidden;
  }

  * {
    color: var(--text);
  }
  
  h1 {
    color: var(--text);
    font-size: 60px;
    letter-spacing: -0.5%;
    font-family: "Geometria bold", sans-serif;
    span {
      color: var(--green);
    }
  }

  .sub-title {
    color: var(--text);
    font-size: 20px;
    line-height: 28.2px;
    font-family: Geometria, sans-serif;
  }

  .container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  button {
    cursor: pointer;
    outline: none;
  }
  .swal2-popup.swal2-show {
    max-width: 500px;
  }
  .swal2-actions {
    display: none;
  }
  .swal2-styled.swal2-confirm {
    font-size: 14px;
    padding: 4px 32px;
    display: none;
  }
  .swal2-icon.swal2-success {
    font-size: 12px;
    margin-top: 0;
  }
  #swal2-title {
    font-size: 22px;
    display: none;
  }
  #swal2-content {
    font-size: 16px;

    img {
      height: 20px;
      vertical-align: text-bottom;
    }
  }
  .privacy {
    padding: 60px 40px;
  }
  @media (max-width: 800px) { 
    h1 {
      font-size: 46px;
    }
  } 

  @media (max-width: 680px) { 
    h1 {
      font-size: 37px;
      line-height: 37px;
      text-align: left;
      letter-spacing: -0.5px;
    }
    .sub-title {
      font-size: 16px;
      line-height: 22.4px;
      text-align: left;
    }
  } 
`;