import styled from 'styled-components';
import heroPage from '../images/handbook.png';
import homeBg from '../images/home-bg.jpg';
import bgLight from '../images/bg-light.jpg';
import {
  isBrowser,
  isMobile,
  isSafari,
  isIOS,
} from "react-device-detect";
// console.log(isBrowser, isMobile, isSafari, isIOS);
const StyledSection = styled.section`
  width: 100%;
  min-height: 650px;
  padding: 40px 0;
  background-image: url(${props => props.img || ''});
  background-size: 286px;
  background-position: 88% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.background-light {
    background-color: #f2f4f3;
    background-image: url(${props => props.img || ''}), url(${bgLight});
    background-position: 88% 50%, center;
    background-attachment: fixed, scroll;
    background-size: 286px, cover;
  }

  ${prosp => (isIOS && isSafari) ? `background-attachment: scroll !important;` : ''}

  h1 > .title-image {
    height: 40px;
    margin: 0 5px;
    vertical-align: middle;
  }

  div > .title-image {
    height: 20px;
    margin: 0 5px;
    vertical-align: middle;
  }
  .col-left {
    max-width: 720px;
    padding-right: 40px;
    width: calc(100% - 320px);
  }

  .app-preview {
    display: none;
  }

  .col-right {
    height: auto;
    width: 100%;
    max-width: 285px;
    min-width: 285px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      width: calc(100% - 50px);
      height: calc(100% - 50px);
      right: 9px;
      top: 19px;
      box-shadow: 1px 20px 38px #000;
      background: transparent;
      backface-visibility: hidden;
      z-index: 1;
    }
    img {
      width: 100%;
      margin-bottom: 0;
      position: relative;
      z-index: 2;
    }
  }

  @media (max-width: 1200px) {
    #details {
      display: none;
    }
  }

  @media (max-width: 800px) {
    background-size: 200px;
    background-size: 200px, cover;

    &.background-light {
      background-size: 200px;
      background-size: 200px, cover;
    }
  }

  @media (max-width: 680px) {
    & {
      background-image: none;
    }
    &.background-light {
      background-attachment: scroll;
      background-image: url(${bgLight});
      background-size: cover;
      background-position: center;
    }
    .container {
      width: 100%;
      flex-direction: column;
      padding: 20px;
    }
    .col-left {
      width: 100%;
      padding-right: 0;
    }

    .app-preview {
      display: block;
    }

    &#hero-area .col-left{
      margin-bottom: 80px;
    }

  }
`;

const StyledHome = styled(StyledSection)`
  min-height: 100vh;
  height: 100%;
  background-image: url(${heroPage}), url(${homeBg});
  background-size: 286px;
  background-position: 88% 50%, center;
  background-attachment: fixed, scroll;
  background-repeat: no-repeat;
  padding-top: 0;
  background-color: #f2f4f3;
  flex-direction: column;
  background-size: 286px, cover;

  .home__list li {
    margin-bottom: 2px;
  }
  .org-title {
    font-family: Geometria;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 135%;
    color: #747474;
    margin-bottom: 15px;
    margin-top: 120px;
  }
  #organizations-below, .col-right .org-title{
    display: none;
  }
  .organizations {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;

      div {
        border-right: 1px solid #CFD1D5;
        padding: 0 10px;
        max-width: 150px;

        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #464646;
        filter: grayscale(1);
        
        span {
          font-size: 8px;
          line-height: 1.4;
          letter-spacing: -1%;
          margin-left: 6px;
        }

        img {
          height: 35px;
        }
      }
      a:hover {
        filter: grayscale(0);
      }
      div:last-of-type {
        border-right: none;
        max-width: auto;

        img {
          height: 25px;
        }
      }
  }

  #frame {
    transform: translate(-50%, -50%);
    position: fixed;
    top: 50%;
    left: 50%;
    width: 256px;
    margin-left: 26vw;
    height: 544px;
    min-height: 544px;
    border-radius: 30px;
    overflow: visible;
    /* box-shadow: 0 0 10px 11px #000; */

    .inside {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: 30px;
      /* box-shadow: 0px 0px 0px 11px #000, 0px 0px 0px 13px #000; */
    }
  }

  .app-preview {
    display: none;
  }

  @media (max-width: 991px) {
    #organizations a span {
      display: none;
    }
    #organizations div:first-of-type {
      padding-left: 0;
    }
    .organizations {
      div {
        padding: 0 19px;
      }
    }
    a:hover {
      span {
        display: block !important;
        position: absolute;
        top: -10px;
        width: 120px;
        transform: translate(-50px, -110%);
        padding: 3px;
        border-radius: 2px;
        background: #e8e8e8;
        font-size: 9px;
        line-height: 1.3;
        text-align: center;
        box-shadow: 0 1px 5px #7b7b7b8c;

        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          right: 55px;
          border: 5px solid;
          border-color: #e6e6e6 transparent transparent;
        }
      }
    }
  }
  @media (max-width: 800px) {
    background-size: 200px;
    background-size: 200px, cover;

    #organizations  {
      flex-wrap: wrap;

      div {
        margin: 10px 0;
      }
    }
  }

  @media (max-width: 680px) {
    background-image: url(${homeBg});
    background-position: center;
    background-attachment: scroll;
    background-size: cover;

   
    .col-left { 
      .org-title {
        display: none;
      }
    }
    #frame {
      display: none;
    }
    #organizations  {
      justify-content: flex-start;
      display: none;

      div {
        margin: 10px 0;
      }
    }
    .organizations {
      div {
        position: relative;
      }
      span {
        display: none;
      }
    }
    .col-right {
      max-width: 100%;

      &::before {
        display: none;
      }
      img {
        width: auto;
      }
    }
    .app-preview {
      display: block;
      max-width: 285px;
      margin: auto;
    }
    #app-store {
      justify-content: center;
    }

    #organizations-below, .col-right .org-title {
      display: flex;
    }
  }
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: transparent;
  margin-bottom: 30px;
  width: 100%;

  .lang-switcher {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 70px;
    text-align: right;
    background-color: #fff;
    cursor: pointer;
    z-index: 999;

    &.active {
      border: 0.5px solid rgb(60 72 94 / 27%);
      border-radius: 4px;
      overflow: hidden;

      .lang-item {
        position: relative;
        opacity: 1;
        transition: .5s;
      }
      .lang-item:hover {
        background-color: #eceded;
      }
    }
    
    .lang-item.active {
      opacity: 1;
    }
    .lang-item {
      opacity: 0;
      position: absolute;
      padding: 3px 5px;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      img {
        height: 15px;
        margin-bottom: 0;
      }
    }
  }
  
  @media (max-width: 680px) { 
    .container {
      flex-direction: row;
    }
    .lang-switcher {
      top: 32px;
      right: 20px;
    }
    #logo img {
      height: 30px;
    }
  } 
  
`;

const StyledDownloadSection = styled.div`
  margin-top: 36px;

  .error + #input-section input {
    border: 1px solid red;
  }

  .message img {
    height: 20px;
    vertical-align: text-bottom;
  }

  #input-section {
    display: flex;
    align-items: center;
    input {
      padding: 6px 16px;
      width: 252px;
      height: 54px;
      background: rgba(255, 255, 255, 0.27);
      border: 1px solid rgba(46, 56, 76, 0.19);
      border-radius: 6px;
      font-size: 14px;

      &:focus {
        outline: none;
        border: 1px solid var(--text);
      }
    }
    button {
      padding: 6px 16px;
      /* position: absolute; */
      width: 175px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 54px;
      background: var(--green);
      border-radius: 6px;
      font-size: 14px;
      line-height: 18px;
      color:#F7F7F7;
      border: 1px solid var(--green);
      margin-left: 10px;
      outline: none;
      cursor: pointer;

      img {
        margin-right: 7px;
      }
    }
  }

  #app-store {
    display: flex;

    .app-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 53px;
      border: 1px solid #2E384C;
      border-radius: 6px;
    }
    .app-button:last-of-type {
      margin-left: 15px;
    }
  }

  @media (max-width: 800px) {
    #input-section {
      display: flex;
      flex-direction: column;

      input, button {
        width: 100%;
      }
      button {
        margin-left: 0;
        margin-top: 15px;
        position: relative;
      }
    }
  }
`;

const StyledFooter = styled.footer`
  background-color: #252525;
  text-align: center;

  h1 {
    color: #F7F7F7;
    font-size: 40px;
    margin: 28px 0;
    text-align: center;
  }
  p {
    color: #F7F7F7;
    font-size: 20px;
    line-height: 140%;
  }
  a {
    color: #23B4A1;
    font-family: Geometria;
    font-size: 15px;
    line-height: 140%;
    text-decoration: none;
  }
  .container {
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;
  }
  #footer {
    margin-top: 0;
    margin-bottom: 20px;
    #input-section {
      justify-content: center;
    }
    #input-section input {
      color: rgba(247,247,247,0.68);
    }
    #app-store {
      justify-content: center;

      .app-button {
        border-color: #F7F7F7;
      }
    }
  }
  #footer button {
    position: relative;
  }
 
  #lower-footer {
    height: 128px;
    background-color: #313131;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      color: rgba(247, 247, 247, 0.67);
      text-decoration: none;
    }
  }

  @media (max-width: 800px) { 
    & {
      #input-section {
        flex-direction: row;
        input {
          color: rgba(247, 247, 247, 0.68);
        }
        button {
          margin-top: 0;
          white-space: nowrap;
          width: auto;
          margin-left: 15px;
        }
      }

      #footer {
        width: 100%;
      }
    }
    
  } 

  @media (max-width: 680px) { 
    h1 {
      font-size: 30px;
    }
    & {
      #footer {
        width: 100%;
      }
    }
  } 
  @media (max-width: 580px) { 
    & {
        #input-section {
        flex-direction: column;
        
        button {
          margin-top: 15px;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  } 
`;

export { StyledSection, StyledHeader, StyledDownloadSection, StyledHome, StyledFooter };